window.axeptioSettings = {
  clientId: "61ec18b0cd19b70d4586267d",
  cookiesVersion: "mvdev-fr",
};

(function (d, s) {
  var t = d.getElementsByTagName(s)[0],
    e = d.createElement(s);
  e.async = true;
  e.src = "//static.axept.io/sdk.js";
  t.parentNode.insertBefore(e, t);
})(document, "script");
